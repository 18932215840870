<!--轮播管理-->
<template>
    <div class="tableBox" style="paddingTop: 30px;">
        <a-form ref="formRef" :model="form">
            <a-form-item label="公告内容">
                <a-textarea v-model:value="form.content" placeholder="请输入公告内容" :rows="4" />
            </a-form-item>
        </a-form>
        <div style="padding:100px 50px 0" class="flex flex-jc-c">
            <a-button block type="primary" @click="saveNotice" style="width:30%">保存</a-button>
        </div>
    </div>
</template>
<script>
import { InboxOutlined } from '@ant-design/icons-vue';
import { defineComponent, toRefs, reactive, getCurrentInstance, onMounted } from 'vue';

export default defineComponent({
    components: {
        InboxOutlined,
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const initData = reactive({
            form: {},
        });
        onMounted(() => {
            NoticeList();
        });
        const NoticeList = () => {
            proxy.http.post('/admin.notice/read').then(response => {
                initData.form.content = response.content;
            })
        };

        const saveNotice = () => {
            proxy.http.post('/admin.notice/save', initData.form).then(() => {
                proxy.message.success('保存成功');
                NoticeList();
            })
        }
        return {
            ...toRefs(initData),
            NoticeList,
            saveNotice
        };
    },
});
</script>
<style lang="less" scoped>
</style>